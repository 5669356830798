import React, { useState } from "react";
import { Cancel } from "@material-ui/icons";
import { withFirebase } from "../../../../utils/Firebase";
import Input from "../../../atoms/Input";
import Select from "../../../atoms/Select";
import { useEffect } from "react";
import Button from "../../../atoms/Button";
import Divider from "../../../atoms/Divider";
import TextArea from "../../../atoms/TextArea";
import countryData from "../../../../../static/new-world-110m.json";

const getCountryOptions = (regions) => {
  let countries = [];
  countryData.objects.gadm36_0.geometries.forEach((c) => {
    if (regions[0] === "all" || regions.indexOf(c.properties.GID_0) !== -1) {
      countries.push({
        value: c.properties.GID_0,
        label: c.properties.NAME_0,
      });
    }
  });
  return countries;
};

const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

const validateInput = (type, text) => {
  if (!text) return false;
  if (type === "email") {
    return text.match("@") && text.match(".");
  }
  if (type === "url") {
    return validURL(text);
  }
};

const NewMinistryProfile = ({
  firebase,
  ministries,
  ministryProfiles,
  profile,
  setEdit,
  count,
  setCount,
}) => {
  console.log("[AddResource]");
  const { currentUser } = firebase ? firebase.auth : { currentUser: null };
  const [userData, setUserData] = useState();
  const [name, setName] = useState(profile ? profile.name : "");
  const [country, setCountry] = useState((profile && profile.country) || "");
  const [contactEmail, setContactEmail] = useState(
    profile ? profile.contactEmail : ""
  );
  // const [contactName, setContactName] = useState(
  //   profile ? profile.contactName : ""
  // );
  const [ministryLink, setMinistryLink] = useState(
    profile ? profile.ministryLink : ""
  );
  const [donationLink, setDonationLink] = useState(
    profile ? profile.donationLink : ""
  );
  const [description, setDescription] = useState(
    profile ? profile.description : ""
  );
  const [selectedMinistry, setSelectedMinistry] = useState("");
  const [publicProfile, setPublicProfile] = useState(
    profile ? profile.publicProfile : false
  );

  useEffect(() => {
    if (currentUser && !userData) {
      firebase
        .user(currentUser.uid)
        .get()
        .then((data) => {
          setUserData(data.data());
        })
        .catch((err) => console.log(err));
    }
  }, [currentUser, firebase, userData]);

  const createUniqueId = async () => {
    const ministryId = Array(20)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");
    const ministryIdExists = await firebase
      .ministry(ministryId)
      .get()
      .then((doc) => doc.exists);
    while (ministryIdExists) {
      console.log(`uid ${ministryId} exists already!`);
      createUniqueId();
    }
    return ministryId;
  };

  const addAvailableMinistry = async (ministryID, facilitatorId) => {
    await firebase
      .addFacilitatorToMinistryData(ministryID, facilitatorId)
      .then(() => {
        alert(`${facilitatorId} added to ministry: ${ministryID}`);
      })
      .catch((err) =>
        alert(
          `error [addFacilitatorToResourceData] ${ministryID}, ${facilitatorId}, \n${JSON.stringify(
            err
          )}`
        )
      );
    await firebase
      .addMinistryIdToFacilitatorData(ministryID, facilitatorId)
      .then(() => {
        alert(`ministry: ${ministryID} added to facilitator`);
      })
      .catch((err) =>
        alert(
          `error [addMinistryIdToFacilitatorData] ${ministryID}, ${facilitatorId}, \n${JSON.stringify(
            err
          )}`
        )
      );
  };

  const saveMinistry = async () => {
    const ministryId = profile
      ? profile.ministryId
      : await createUniqueId().then((id) => id);
    const ministryData = {
      ministryId,
      facilitators: [currentUser.uid],
      name,
      country,
      contactEmail,
      ministryLink,
      donationLink,
      description,
      publicProfile: Boolean(publicProfile),
    };
    await firebase
      .ministry(ministryId)
      .set(ministryData)
      .then(() => {
        setCount(count + 1);
        if (profile) {
          setEdit(false);
        }
        if (profile) return;
        setName("");
        setCountry("");
        setContactEmail("");
        // setContactName("");
        setDonationLink("");
        setDescription("");
        setMinistryLink([]);
        return;
      })
      .catch((err) => {
        alert("failed to save ministry");
        return;
      });
    if (profile) return;
    await firebase
      .addMinistryIdToFacilitatorData(ministryId, currentUser.uid)
      .then(() => {
        alert(`ministry: ${ministryId} added to facilitator`);
        setCount(count + 1);
        if (profile) {
          setEdit(false);
        }
      })
      .catch((err) =>
        alert(
          `error [addMinistryIdToFacilitatorData] ${ministryId}, ${currentUser.uid}`
        )
      );
    setCount(count + 1);
    if (profile) {
      setEdit(false);
    }
  };

  const isValid =
    !!currentUser &&
    !!currentUser.uid &&
    !!name &&
    !!validateInput("email", contactEmail) &&
    !!validateInput("url", ministryLink) &&
    !!description;

  const filteredMinistries = () => {
    const profileMinistryIds = ministryProfiles.map((m) => m.ministryId);
    return ministries.filter(
      (m) => profileMinistryIds.indexOf(m.ministryId) === -1
    );
  };
  return (
    <div className="newMinistry">
      {!profile && ministries.length > 0 ? (
        <>
          <h4 className="newMinistry__header">
            SELECT WHICH MINISTRY YOU BELONG TO:
          </h4>
          <div className="newMinistry__available">
            <Select
              options={filteredMinistries().map((m) => {
                return {
                  value: m.ministryId,
                  label: m.name,
                };
              })}
              labelName="Ministries"
              // className="newMinistry__languageContainer__select"
              value={selectedMinistry}
              setValue={setSelectedMinistry}
            />
            <Button
              disabled={!selectedMinistry || !currentUser}
              onClick={() =>
                addAvailableMinistry(selectedMinistry, currentUser.uid)
              }
              text="Save Ministry"
            />
          </div>
          <Divider />
        </>
      ) : null}
      <div className="ministry__header">
        <h3 className="ministry__profile__name">
          {profile ? "EDIT " : "CREATE NEW "}MINISTRY PROFILE:
        </h3>
        {profile && (
          <div onClick={() => setEdit(false)}>
            <Cancel color="#1F79BC" />
          </div>
        )}
      </div>
      <div>
        <Input
          className="inputMarginBottom"
          labelName="Ministry Name *"
          required={true}
          type="text"
          onChange={(event) => setName(event.target.value)}
          value={name}
        />
      </div>
      {/* {userData && (
        <div className="newMinistry__languageContainer">
          <div className="newMinistry__languageContainer__select">
            <Select
              required={true}
              options={getCountryOptions(userData.regions)}
              labelName="Country *"
              className="newMinistry__languageContainer__select"
              value={country}
              setValue={setCountry}
            />
          </div>
        </div>
      )} */}
      <TextArea
        name="moreinfo"
        cols="60"
        rows="10"
        required={true}
        labelName="Ministry Description *"
        value={description}
        onChange={setDescription}
      />
      <Input
        className="inputMarginBottom"
        labelName="Ministry Website *"
        required={true}
        type="textarea"
        onChange={(event) => setMinistryLink(event.target.value)}
        value={ministryLink}
      />
      <Input
        className="inputMarginBottom"
        labelName="Donation Website"
        required={true}
        type="textarea"
        onChange={(event) => setDonationLink(event.target.value)}
        value={donationLink}
      />
      <Input
        className="inputMarginBottom"
        labelName="Contact Email *"
        required={true}
        type="textarea"
        onChange={(event) => setContactEmail(event.target.value)}
        value={contactEmail}
      />
      <div className="createUserForm__checkbox">
        <input
          name="publicCheckBox"
          type="checkbox"
          checked={publicProfile}
          onChange={() => setPublicProfile(!publicProfile)}
        />
        <p className="createUserForm__checkbox__label">
          Public Ministry Profile
        </p>
      </div>
      {/* <Input
        className="inputMarginBottom"
        labelName="Name of Contact"
        required={true}
        type="textarea"
        onChange={(event) => setContactName(event.target.value)}
        value={contactName}
      /> */}
      <Button disabled={!isValid} onClick={() => saveMinistry()} text="SAVE" />
    </div>
  );
};

export default withFirebase(NewMinistryProfile);
