import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Edit, CheckCircle, Cancel } from "@material-ui/icons"
import NewMinistryProfile from "./NewMinistryProfile"
import Button from "../../../atoms/Button"
import UserTable from "./UserTable"
import TeamsTable from "./TeamsTable"
import { withFirebase } from "../../../../utils/Firebase"
import { CREATE_USER } from "../../../../constants/routes"
import Colors from "../../../../constants/Colors"
import { validateInput } from "../../../../functions/validateInput"
import Input from "../../../atoms/Input"
import countryNames from "../../../../../static/countryNames.json"

const MinistryProfile = ({
  firebase,
  profile,
  ministries,
  ministryProfiles,
  setCount,
  count,
}) => {
  const [edit, setEdit] = useState(false)
  const [addUser, setAddUser] = useState(false)
  const [users, setUsers] = useState([])
  const [teams, setTeams] = useState([])
  const [userEmail, setUserEmail] = useState("")
  const [view, setView] = useState("teams")

  useEffect(() => {
    if (view === "teams") {
      getTeams()
    } else if (view === "users") {
      getUsers()
    }
  }, [count, edit, view, profile])

  const getUsers = () => {
    firebase
      .users()
      .where("ministries", "array-contains", profile.ministryId)
      .get()
      .then((querySnapshot) => {
        let array = []
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          array.push(doc.data())
        })
        setUsers(array)
      })
      .catch((err) => console.log(err))
  }

  const getTeams = () => {
    firebase
      .teams()
      .where("ministryId", "==", profile.ministryId)
      .get()
      .then((querySnapshot) => {
        let array = []
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          array.push(doc.data())
        })
        setTeams(array)
      })
      .catch((err) => console.log(err))
  }

  const addMinistryToUserProfile = async (email, ministryId) => {
    const user = await firebase
      .users()
      .where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        let array = []
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          array.push(doc.data())
        })
        return array[0]
      })
      .catch((err) => console.log(err))
    console.log(user)
    await firebase
      .addMinistryIdToFacilitatorData(ministryId, user.uid)
      .then(() => alert(`${email} was added to this ministry`))
      .catch((err) => console.log(err))
    setCount(count + 1)
    setAddUser(false)
  }

  const isValid = validateInput("email", userEmail)

  return (
    <div className="ministry__edit">
      {edit ? (
        <NewMinistryProfile
          ministries={ministries}
          ministryProfiles={ministryProfiles}
          profile={profile}
          setEdit={setEdit}
          setCount={setCount}
          count={count}
        />
      ) : (
        <div className="ministry__profile">
          <div className="ministry__header">
            <h3 className="ministry__profile__name">{`${profile.name} (${
              countryNames[profile.country]
            })`}</h3>
            <div onClick={() => setEdit(!edit)}>
              <Edit color="#1F79BC" />
            </div>
          </div>
          {edit && <div>EDITING!</div>}
          <p className="ministry__profile__description">
            {profile.description}
          </p>
          <p className="ministry__profile__link">
            Website Link: {profile.ministryLink}
          </p>
          <p className="ministry__profile__link">
            Donation Link: {profile.donationLink}
          </p>
          <p className="ministry__profile__link">
            Email Contact: {profile.contactEmail}
          </p>
          <p className="ministry__profile__link">
            Public Profile:{" "}
            {profile.publicProfile ? (
              <CheckCircle style={{ color: Colors.primary }} />
            ) : (
              <Cancel style={{ color: Colors.primary }} />
            )}
          </p>
          <div className="ministry__profile__viewBtnContainer">
            <div className="ministry__profile__viewBtn">
              <Button
                disabled={view === "teams"}
                onClick={() => setView("teams")}
                text="TEAMS"
              />
            </div>
            <div className="ministry__profile__viewBtn">
              <Button
                disabled={view === "users"}
                onClick={() => setView("users")}
                text="USERS"
              />
            </div>
          </div>
          {view === "teams" && (
            <TeamsTable
              teams={teams}
              profile={profile}
              count={count}
              setCount={setCount}
              country={profile.country}
            />
          )}
          {view === "users" && (
            <UserTable
              users={users}
              profile={profile}
              count={count}
              setCount={setCount}
            />
          )}
          {/* <div className="ministry__linkContainer">
            <div className="ministry__link__left">
              <Button
                type="submit"
                // disabled={!selectedCountry || !isValid || !currentUser}
                onClick={() => setAddUser(!addUser)}
                text={addUser ? "X CLOSE" : "+ ADD EXISTING USER TO MINISTRY"}
              />
            </div>
            {!addUser && (
              <Link to={CREATE_USER} className="ministry__link">
                <Button
                  type="submit"
                  // disabled={!selectedCountry || !isValid || !currentUser}
                  onClick={() => setAddUser(!addUser)}
                  text="+ CREATE NEW USER"
                />
              </Link>
            )}
          </div>
          {addUser && (
            <div className="ministry__addUser">
              <Input
                className="ministry__addUser__input"
                labelName="User Email"
                type="text"
                onChange={(event) => setUserEmail(event.target.value)}
                value={userEmail}
              />
              <Button
                className="ministry__addUser__button"
                type="submit"
                disabled={!isValid}
                onClick={() =>
                  addMinistryToUserProfile(userEmail, profile.ministryId)
                }
                text="ADD USER"
              />
            </div>
          )} */}
        </div>
      )}
    </div>
  )
}

export default withFirebase(MinistryProfile)
