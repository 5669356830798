import React, { useState } from "react";

const SearchComponent = ({ items, field, setFilteredItems }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    if (!event.target.value || event.target.value === "") {
      setFilteredItems(items);
    } else {
      setFilteredItems(
        items.filter((item) =>
          item[field].toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  };

  console.log(`searchTerm: "${searchTerm}"`);
  return (
    <div>
      <input
        style={{
          padding: '10px',
          margin: '5px',
          fontSize: '18px',
          borderRadius: '5px'
        }}
        type="text"
        placeholder={`Search by ${field === "name" ? "team " : ""}${field}...`}
        value={searchTerm}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default SearchComponent;
