import React from "react";
import { compose } from "recompose";
import Layout from "../utils/layout";
import { withAuthorization, withEmailVerification } from "../utils/Session";
import Ministry from "../components/scenes/Ministry";

const condition = authUser => !!authUser;
const MinistryPage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(Ministry);

export default () => {
  return (
    <Layout>
      <MinistryPage />
    </Layout>
  );
};
