import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../utils/Firebase";
import OutlinedButton from "../../atoms/OutlinedButton";
import NewMinistryProfile from "./atoms/NewMinistryProfile";
import MinistryProfile from "./atoms/MinistryProfile";
import Divider from "../../atoms/Divider";
import Select from "../../atoms/Select";
import MinistrySelect from "../../molecules/MinistrySelect";
import CountryISOCodes from "../../../data/countryISOcodes.json";
import getCountryFlag from "../../../scripts/getCountryFlag";
// import ResourceTable from "./atoms/ResourceTable";

const UserMinistries = ({ firebase }) => {
  // const { currentUser } = firebase ? firebase.auth : { currentUser: null }
  const [ministryProfiles, setMinistryProfiles] = useState([]);
  const [ministries, setMinistries] = useState([]);
  const [selectedMinistry, setSelectedMinistry] = useState();
  const [selectedMinistryData, setSelectedMinistryData] = useState();
  // const [showEdit, setShowEdit] = useState(false)
  const [count, setCount] = useState(0);

  // useEffect(() => {
  //   if (currentUser) {
  //     console.log("useEffect: getMinistries")
  //     getMinistries()
  //   }
  //   if (ministries.length === 1 && !selectedMinistry) {
  //     setSelectedMinistry(ministries[0].ministryId)
  //     setSelectedMinistryData(ministries[0])
  //   }
  // }, [currentUser, firebase, count, ministries.length])

  // const getMinistries = async () => {
  //   // const { regions } = await firebase
  //   //   .user(currentUser.uid)
  //   //   .get()
  //   //   .then((data) => data.data());
  //   // console.log(regions);
  //   // const filterArgs =
  //   //   regions[0] === "all"
  //   //     ? ["facilitators", "array-contains", currentUser.uid]
  //   //     : ["country", "in", regions];
  //   await firebase
  //     .ministries()
  //     .where("facilitators", "array-contains", currentUser.uid)
  //     .get()
  //     .then((querySnapshot) => {
  //       let array = []
  //       querySnapshot.forEach((doc) => {
  //         // doc.data() is never undefined for query doc snapshots
  //         array.push(doc.data())
  //       })
  //       setMinistries(array)
  //       const profiles = array.filter(
  //         (m) => m.facilitators.indexOf(currentUser.uid) !== -1
  //       )
  //       setMinistryProfiles(profiles)
  //     })
  //     .catch((err) => console.log(err))
  // }

  return (
    <div className="home container">
      <div className="ministry__details">
        <h1 className="ministry__title">Ministry Profile</h1>
      </div>

      {/* {showEdit || !ministryProfiles ? (
        <div>
          <h3 className="ministry__profile__name__edit">ADD NEW MINISTRY</h3>
          <NewMinistryProfile
            ministries={ministries}
            count={count}
            setCount={setCount}
            ministryProfiles={ministryProfiles}
          />
        </div>
      ) : null} */}
      <div className="ministry__divider">
        <Divider />
      </div>
      <MinistrySelect
        selectedMinistry={selectedMinistry}
        setSelectedMinistry={setSelectedMinistry}
        setSelectedMinistryData={setSelectedMinistryData}
      />
      <div className="ministry">
        {selectedMinistry && selectedMinistryData && (
          <MinistryProfile
            profile={selectedMinistryData}
            ministries={ministries}
            ministryProfiles={ministryProfiles}
            count={count}
            setCount={setCount}
          />
        )}
      </div>
    </div>
  );
};

export default withFirebase(UserMinistries);
