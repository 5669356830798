import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import HorizontalBarChart from '../scenes/Report/atoms/HorizontalBar'

export default function FormDialog({
  open,
  setOpen,
  teamData,
}) {
  console.log(teamData)
  const { accepted, delivered, have, unfinished, need, rejected, bibles } = teamData.reportData;
  const totalLocations = accepted || 0 
    + delivered || 0
    + have || 0
    + unfinished || 0
    + need || 0
    + rejected || 0
  
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" style={{textAlign: 'center'}}>{teamData.name || "(No Team Name)"}</DialogTitle>
      <DialogContent>
        <div
        style={{
          minWidth: '70%',
          borderRadius: 15,
          marginBottom: 20
        }}
        className="mapNTotalDiv"
        >
          <div className="totalCountReport" style={{flexDirection: 'row',
          borderRadius:15}}>
                <p className="count" style={{fontSize: '3em'}}>
                  <span className="countNumber">{bibles ? bibles.toLocaleString() : 0}</span>
                  <span className="countTextDiv">
                    <span className="countText">Bibles shared</span>
                  </span>
                </p>
                <p className="count" style={{fontSize: '3em'}}>
                  <span className="countNumber">
                    {(totalLocations || 0).toLocaleString()}
                  </span>
                  <span className="countTextDiv">
                    <span className="countText">Locations visited</span>
                  </span>
                </p>
              </div>
                                  </div>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 5,
            paddingRight: 10,
            marginBottom: 20,
          }}
        >
        <HorizontalBarChart data={teamData.reportData} /></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="#004689">
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
