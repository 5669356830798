import React, { useState, useEffect} from "react";
import { withFirebase } from "../../../../utils/Firebase";
import { Tooltip } from "@material-ui/core";
import Search from './Search';

const UserTable = ({ firebase, users, setCount, count, profile }) => {
  const { currentUser } = firebase && firebase.auth;
  const [filteredUsers, setFilteredUsers] = useState(users)

  useEffect(() => {
    if (filteredUsers.length < 1) {
      setFilteredUsers(users || []);
    }
  }, [users]);

  const removeUser = async (userUid, ministryId, isFacilitator) => {
    const userData = await firebase
      .user(userUid)
      .get()
      .then((doc) => doc.data());

    if (isFacilitator) {
      await firebase
        .removeFacilitatorFromMinistryData(ministryId, userUid)
        .then(() => console.log("facilitator removed from ministry"));
    }
    await firebase.removeMinistryFromUser(userUid, ministryId).then(() => {
      setCount(count + 1);
    });
    if (userData.teams.length > 1) {
      const snapshot = await firebase
        .teams()
        .where("teamId", "in", userData.teams)
        .where("ministryId", "==", ministryId)
        .get();
      const ministryTeams = [];
      snapshot.forEach((doc) => ministryTeams.push(doc.id));
      console.log(ministryTeams);
      ministryTeams.forEach(async (t) => {
        console.log(t);
        await firebase.removeTeamFromUser(userUid, t).then(() => {
          setCount(count + 1);
        });
      });
    } else {
      setCount(count + 1);
    }
  };

  const makeFacilitator = async (userUid, ministryId) => {
    // add id to ministry data
    await firebase
      .addFacilitatorToMinistryData(ministryId, userUid)
      .then(() => console.log("user added to ministry facilitators"));
    // add "FACILITATOR" to user roles
    await firebase.makeUserFacilitator(userUid).then(() => {
      setCount(count + 1);
    });
  };

  const createUniqueUid = async (type) => {
    const uid = Array(28)
      .fill("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
      .map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
      .join("");
    const uidExists = await firebase[type](uid)
      .get()
      .then((doc) => doc.exists);
    while (uidExists) {
      console.log(`uid ${uid} exists already!`);
      createUniqueUid(type);
    }
    return uid;
  };

  const createNewTeam = async (user, ministryId, country) => {
    const teamId = await createUniqueUid("team").then((id) => id);
    await firebase
      .user(user.uid)
      .update({
        roles: firebase.fieldValue.arrayUnion("TEAMLEADER"),
        teams: firebase.fieldValue.arrayUnion(teamId),
      })
      .then(() => console.log(`${user.uid} is now a team leader`));
    await firebase
      .team(teamId)
      .set({
        invites: 10,
        leaders: [user.uid],
        ministryId: ministryId,
        facilitator: currentUser.uid,
        teamId: teamId,
        email: user.email,
        country,
      })
      .then(() => alert(`A team has been created for ${user.email}`));
  };

  return (
    <div>
      <div className="resources__search">
        <Search
          items={users}
          field={"email"}
          setFilteredItems={setFilteredUsers}
        />
      </div>
      <table className="resources__table">
        <tbody>
          <tr className="resources__table__row">
            <th className="resources__table__cell">Email</th>
            <th className="resources__table__cell">Role</th>
            {/* <th className="resources__table__cell">Team</th> */}
            {/* <th className="resources__table__cell">Last Signed In</th> */}
            <th className="resources__table__cell">Action</th>
          </tr>
          {filteredUsers.map((u, index) => {
            const isFacilitatorOfMinistry =
              profile.facilitators.indexOf(u.uid) !== -1;
            const isTeamLeader = u.roles.indexOf("TEAMLEADER") !== -1;
            // if (u.uid === currentUser.uid) return null;
            // if (u.roles.length > 0 && u.roles.indexOf("ADMIN") !== -1)
            //   return null;
            return (
              <tr key={`${u.email}-${index}`} className="resources__table__row">
                <td className="resources__table__cell">
                  {u.email} - {u.teams.length} team(s)
                </td>
                <td className="resources__table__cell">
                  {isFacilitatorOfMinistry
                    ? "FACILITATOR"
                    : isTeamLeader
                    ? "TEAM LEADER"
                    : ""}
                </td>
                {/* <td className="resources__table__cell">
                {isFacilitatorOfMinistry
                  ? "FACILITATOR"
                  : isTeamLeader
                  ? "TEAM LEADER"
                  : ""}
              </td> */}
                {/* <td className="resources__table__cell">{u.lastSignedIn}</td> */}
                <td className="resources__table__cell">
                  {profile.facilitators.indexOf(u.uid) === -1 && (
                    <Tooltip
                      title="This will remove the user from this ministry, but it will
                  not delete the user."
                    >
                      <div
                        className="resources__removeBtn__red"
                        onClick={() => {
                          removeUser(
                            u.uid,
                            profile.ministryId,
                            profile.facilitators.indexOf(u.uid) !== -1
                          );
                          setCount(count + 1);
                        }}
                      >
                        - REMOVE
                      </div>
                    </Tooltip>
                  )}

                  <Tooltip title="This will create a new team for the user to manage. The previous team will become inactive.">
                    <div
                      className="resources__removeBtn__blue"
                      onClick={() => {
                        createNewTeam(u, profile.ministryId, profile.country);
                        setCount(count + 1);
                      }}
                    >
                      {u.roles &&
                      (u.roles[0] === "TEAMLEADER" ||
                        u.roles[0] === "FACILITATOR")
                        ? "+ CREATE NEW TEAM"
                        : "+ MAKE TEAM LEADER (NEW TEAM)"}
                    </div>
                  </Tooltip>
                  {profile.facilitators.indexOf(u.uid) === -1 && (
                    <Tooltip title="This action cannot be reversed">
                      <div
                        className="resources__removeBtn__blue"
                        onClick={() => {
                          makeFacilitator(u.uid, profile.ministryId);
                          setCount(count + 1);
                        }}
                      >
                        + MAKE FACILITATOR
                      </div>
                    </Tooltip>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default withFirebase(UserTable);
