import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function FormDialog({
  open,
  setOpen,
  selectedTeam,
  action,
  type,
}) {
  const [text, setText] = useState("");
  console.log("selectedTeam", selectedTeam);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {type === "addUser" ? "Add User To Team" : "Edit Team Name"}
      </DialogTitle>
      <DialogContent>
        {type === "addUser" && (
          <DialogContentText>Add User Email Address</DialogContentText>
        )}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={type === "addUser" ? "Email Address" : "Team Name"}
          type="email"
          fullWidth
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="#004689">
          CANCEL
        </Button>
        <Button onClick={() => action(text, selectedTeam)} color="#1F79BC">
          {type === "addUser" ? "ADD USER" : "SAVE"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
