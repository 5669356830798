import React, { useEffect, useState } from "react"
import { Link } from "gatsby";
import { withFirebase } from "../../../../utils/Firebase"
import Modal from "../../../atoms/Modal"
import TeamStatsModal from "../../../atoms/TeamStatsModal"
import Search from "./Search";

const TeamsTable = ({ firebase, teams, setCount, count, country }) => {
  const [open, setOpen] = useState(false)
  const [openTeamStats, setOpenTeamStats] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState()
  const [teamStats, setTeamStats] = useState()
  const [modalType, setModalType] = useState()
  const [filteredTeams, setFilteredTeams] = useState(teams);

  useEffect(() => {
    if (filteredTeams.length < 1) {
      setFilteredTeams(teams || [])
    }
   },[teams])
  const addInvites = async (teamId, quantity) => {
    await firebase.addInvites(teamId, quantity).then(() => setCount(count + 1))
  }

  const changeTeamName = async (name, team) => {
    await firebase.editTeamName(name, team.teamId).then(() => {
      setCount(count + 1)
      setOpen(false)
    })
  }

  const addUserToTeam = async (email, t) => {
    let randomPassword = null
    let uid = null
    let existingUserData = null
    const snapshot = await firebase.getUserByEmail(email)
    if (snapshot.empty) {
      console.log("No matching users.")
    }
    snapshot.forEach((doc) => {
      existingUserData = doc.data()
    })
    if (!existingUserData) {
      // Create Access Code
      randomPassword = Array(10)
        .fill("123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjklmnopqrstuvwxyz")
        .map(function (x) {
          return x[Math.floor(Math.random() * x.length)]
        })
        .join("")

      // Create UID
      uid = await firebase
        .createUniqueUid("users")
        .then((id) => id)
        .catch((err) =>
          console.log(
            "[createUserFormBase] firebase.createUniqueId [user]",
            err
          )
        )
      await firebase
        .user(uid)
        .set({
          uid,
          email: email,
          accessCode: randomPassword,
          facilitators: [t.facilitator],
          roles: [],
          teams: [t.teamId],
          regions: [t.country],
          ministries: [t.ministryId],
        })
        .then(() =>
          alert(`${email} has been added to team: ${t.name || t.teamId}`)
        )
    } else {
      await firebase
        .addTeamToUser(existingUserData.uid, t.teamId, t.ministryId)
        .then(() =>
          alert(`${email} has been added to team: ${t.name || t.teamId}`)
        )
    }
    setCount(count + 1)
    setOpen(false)
  }

  return (
    <div>
      <div className="resources__search">
        <Search
          items={teams}
          field={"name"}
          setFilteredItems={setFilteredTeams}
        />
      </div>
      <table className="resources__table">
        <tbody>
          <tr className="resources__table__row">
            <th className="resources__table__cell">Team Name</th>
            <th className="resources__table__cell">Leader Email</th>
            <th className="resources__table__cell">Invites</th>
            <th className="resources__table__cell">Action</th>
          </tr>
          {filteredTeams.map((t, index) => {
            console.log(t);
            return (
              <tr
                key={`${t.teamId}-${index}`}
                className="resources__table__row"
              >
                <td
                  className="resources__table__cell__centerText"
                  style={
                    !t.country
                      ? {
                          textDecoration: "line-through",
                          color: "grey",
                          fontStyle: "italic",
                        }
                      : {}
                  }
                  onClick={() => {
                    setModalType("changeTeamName");
                    setSelectedTeam(t);
                    setOpen(true);
                  }}
                >
                  {t.name ? t.name : "Click to edit Team Name"}
                </td>
                <td
                  className="resources__table__cell__centerText"
                  style={
                    !t.country
                      ? {
                          textDecoration: "line-through",
                          color: "grey",
                          fontStyle: "italic",
                        }
                      : {}
                  }
                >
                  {t.email}
                </td>
                <td
                  className="resources__table__cell__centerText"
                  style={
                    !t.country
                      ? {
                          textDecoration: "line-through",
                          color: "grey",
                          fontStyle: "italic",
                        }
                      : {}
                  }
                >
                  {t.invites}
                </td>
                <td className="resources__table__cell__centerText">
                  <div
                    className="resources__removeBtn__blue"
                    onClick={() => {
                      addInvites(t.teamId, 10);
                    }}
                  >
                    <Link to="/team/" state={{ teamData: t }}>
                      TEAM DATA
                    </Link>
                  </div>
                  {t.reportData && (
                    <div
                      className="resources__removeBtn__red"
                      onClick={() => {
                        setTeamStats(t);
                        setOpenTeamStats(true);
                      }}
                    >
                      STATISTICS
                    </div>
                  )}
                  <div
                    className="resources__removeBtn__blue"
                    onClick={() => {
                      addInvites(t.teamId, 10);
                    }}
                  >
                    +10 INVITES
                  </div>
                  <div
                    className="resources__removeBtn__red"
                    onClick={() => {
                      firebase
                        .team(t.teamId)
                        .update({
                          invites: 0,
                        })
                        .then(() => setCount(count + 1));
                    }}
                  >
                    RESET INVITES
                  </div>
                  <div
                    className="resources__removeBtn__blue"
                    onClick={() => {
                      setModalType("addUser");
                      setSelectedTeam(t);
                      setOpen(true);
                    }}
                  >
                    ADD USER TO TEAM
                  </div>
                  <div
                    className="resources__removeBtn__red"
                    onClick={() => {
                      t.country
                        ? firebase
                            .disableTeam(t.teamId)
                            .then(() => setCount(count + 1))
                        : firebase
                            .enableTeam(t.teamId, country)
                            .then(() => setCount(count + 1));
                    }}
                  >
                    {t.country ? "DISABLE TEAM" : "ENABLE TEAM"}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {open && (
        <Modal
          open={open}
          setOpen={setOpen}
          selectedTeam={selectedTeam}
          action={modalType === "addUser" ? addUserToTeam : changeTeamName}
          type={modalType}
        />
      )}
      {openTeamStats && (
        <TeamStatsModal
          open={openTeamStats}
          setOpen={setOpenTeamStats}
          teamData={teamStats}
        />
      )}
    </div>
  );
}

export default withFirebase(TeamsTable)
